//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'navbarResponsive',
  data() {
    return {
      isOffset: false,
    }
  },
  computed: {
    ...mapState({
      sideLinks: (state) => state.links,
    }),
  },
  mounted() {
    window.addEventListener('scroll', this.showBg)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showBg)
  },
  methods: {
    toggleMenu(boolean) {
      this.$store.commit('SET_MENU', boolean)
    },
    showBg() {
      window.scrollY > 1 ? (this.isOffset = true) : (this.isOffset = false)
    },
  },
}
